// проблема в том что alert у списков нету скролбара и не очевидно что список больше чем видимая его часть
ion-alert.alert-scrollbar {
  .alert-radio-group::-webkit-scrollbar, .alert-checkbox-group::-webkit-scrollbar {
    width: 0.5em;
    display: block !important;
  }

  .alert-radio-group::-webkit-scrollbar-track, .alert-checkbox-group::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-border-radius: 10px;
    border-radius: 10px;
  }

  .alert-radio-group::-webkit-scrollbar-thumb, .alert-checkbox-group::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: rgba(var(--ion-color-primary-rgb), 0.8);
    //-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);

    &:window-inactive {
      background: rgba(var(--ion-color-primary-rgb), 0.4);
    }
  }
}

ion-alert.ion-text-wrap {
  .alert-radio-group {
    .alert-radio-label {
      white-space: normal;
    }
  }
}

ion-alert {
  &.ion-text-wrap, .ion-text-wrap {
    .alert-radio-label {
      white-space: normal !important;
    }
  }

  &.alert-w300 {
    --min-width: 300px;
  }

  &.alert-w350 {
    --min-width: 350px;
    @media (max-width: 349px) {
      --min-width: 300px;
    }
  }

  &.alert-w400 {
    --min-width: 400px;
    @media (max-width: 399px) {
      --min-width: 300px;
    }
  }
}

ion-action-sheet {
  .action-sheet-button {
    &.ion-color {
      --button-color: var(--ion-color-base);
      --button-color-hover: var(--ion-color-base);
    }
  }
}

ion-infinite-scroll {
  ion-spinner {
    --color: var(--ion-color-primary, #000);
  }
}

ion-refresher {
  z-index: 1;

  ion-spinner, ion-icon {
    --color: var(--ion-color-primary, #000);
  }

  ion-icon {
    color: var(--ion-color-primary, #000);
  }
}


ion-loading {
  ion-spinner {
    color: var(--ion-color-primary) !important;
  }
}

ion-alert {
  .alert-wrapper {
    border-radius: 12px !important;
  }
}

ion-card {
  border-radius: 12px !important;
}

ion-loading {
  .loading-wrapper {
    border-radius: 12px !important;
  }
}

ion-button {
  height: 42px;
  --border-radius: 8px;
  --padding-top: 3px;
  --padding-bottom: 3px;
}
