ion-modal.virtual-keyboard {
  --virtual-keyboard-width: 360px;
  --virtual-keyboard-height: 360px;

  @media only screen and (max-width: 600px) {
    --virtual-keyboard-width: 100%;
  }
  @media only screen and (max-height: 700px) {
    --virtual-keyboard-height: 305px;
  }

  --width: var(--virtual-keyboard-width, 380px);
  --height: calc(var(--virtual-keyboard-height, 330px) + var(--ion-safe-area-bottom, 0px));

  --ion-backdrop-color: transparent;

  &:not(.virtual-keyboard-no-show-backdrop) {
    .modal-wrapper {
      border-top: 1px solid #ccc;
      top: calc(100% - (var(--height)));
      position: absolute;
    }
  }

  &.virtual-keyboard-no-show-backdrop {
    top: calc(100% - (var(--height)));
    position: absolute;
  }

}

input.virtual-keyboard-focused {
  outline: var(--ion-color-primary) auto 1px;
}
