ion-input, ion-select, ion-item, ion-datetime-button {
  &.field-form {
    --background: var(--ion-color-light-tint) !important;
    --border-color: var(--ion-color-light-shade) !important;
    --border-width: 2px !important;;
    --highlight-color: initial !important;
    --border-radius: 4px !important;


    .label-text-wrapper {

    }

    .input-label-placement-floating {


      .label-text-wrapper {
        transform: translateY(120%) scale(1) !important;
      }
    }


    &.has-focus, &.select-expanded { // ion-margin-bottom field-form md has-placeholder ion-focusable select-ltr select-fill-outline select-label-placement-floating hydrated select-expanded
      --border-color: var(--ion-color-primary) !important;
    }

    &.ion-invalid.ion-touched {
      --border-color: var(--ion-color-danger) !important;
    }
  }
}

.error-label {
  padding: 5px 15px;
  font-size: 12px;
  z-index: 1;
  color: var(--ion-color-danger);
}

ion-item.field-form ion-datetime-button {
  display: block;

  width: 100%;

  &::part(native) {
    background: none;
    margin: 0;
    width: 100%;
    height: 52px;
    text-align: left;
    padding: 0 calc(30px + 1rem) 0 16px;
    border-radius: 0;
    display: block;
    overflow: hidden;
  }

  + ion-icon {
    position: absolute;
    right: 16px;
  }
}

ion-item.field-form:has(ion-datetime-button) {
  overflow: hidden;

  &::part(native) {
    --native-padding-left: 0 !important;
    --padding-start: 0;
    --inner-padding-end: 0;
  }
}
