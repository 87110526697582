
ion-app {
  --side-max-width: 20%;
  --side-min-width: 270px;

  ion-menu {
    --side-max-width: 20%;
    --side-min-width: 270px;
  }

  // Позиционирование модальных окон, алертов и т.д. относительно меню
  .split-pane-visible {
    & ~ ion-loading .loading-wrapper {
      margin-left: max(var(--side-min-width, 270px), var(--side-max-width, 28%));
    }

    & ~ ion-alert .alert-wrapper {
      margin-left: max(var(--side-min-width, 270px), var(--side-max-width, 28%));
    }

    & ~ ion-modal.modal-sheet {
      margin-left: max(var(--side-min-width, 270px), var(--side-max-width, 28%));
    }
  }
}

