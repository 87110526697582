/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

@import "./assets/fonts/Roboto/Roboto";

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import '@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

@import "./theme/custom-ion-colors";
@import "./styles/swiper";
@import "./styles/base";
@import "./styles/fix-ionic";
@import "./styles/ion-form.fields-border";
@import "./styles/utils-ionic";
@import "./styles/virtual-keyboard";
@import "./app/app.scss";

.alert-button {
  &:disabled {
    opacity: .6;
  }
}

ion-header {
  ion-title {
    .app-version-title {
      display: inline-block;
      vertical-align: bottom;
      font-size: 13px;
    }
  }
}
