@import "mixins/ion-color";
@import "functions/ionic.functions.color";

:root {
  @include ion-color('white') {

    $white-color: #FFFFFF !default;
    $white-color-contrast: #090928;

    --ion-color-white: #{$white-color};
    --ion-color-white-rgb: color-to-rgb-list($white-color);
    --ion-color-white-contrast: #{$white-color-contrast};
    --ion-color-white-contrast-rgb: color-to-rgb-list($white-color-contrast);
    --ion-color-white-shade: get-color-shade($white-color);
    --ion-color-white-tint: get-color-tint($white-color);
  }

  @include ion-color('dark') {

    $dark-color: #090928 !default;
    $dark-color-contrast: #FFFFFF;

    --ion-color-dark: #{$dark-color};
    --ion-color-dark-rgb: color-to-rgb-list($dark-color);
    --ion-color-dark-contrast: #{$dark-color-contrast};
    --ion-color-dark-contrast-rgb: color-to-rgb-list($dark-color-contrast);
    --ion-color-dark-shade: get-color-shade($dark-color);
    --ion-color-dark-tint: get-color-tint($dark-color);
  }

  @include ion-color('orange') {

    $orange-color: #F2994A !default;
    $orange-color-contrast: #090928;

    --ion-color-orange: #{$orange-color};
    --ion-color-orange-rgb: color-to-rgb-list($orange-color);
    --ion-color-orange-contrast: #{$orange-color-contrast};
    --ion-color-orange-contrast-rgb: color-to-rgb-list($orange-color-contrast);
    --ion-color-orange-shade: get-color-shade($orange-color);
    --ion-color-orange-tint: get-color-tint($orange-color);
  }
}
