/**
 * @see https://github.com/ionic-team/ionic-framework/blob/main/core/src/themes/ionic.functions.color.scss
 */


// Gets the active color's css variable from a variation. Alpha is optional.
// --------------------------------------------------------------------------------------------
// Example usage:
// current-color(base) => var(--ion-color-base)
// current-color(contrast, 0.1) => rgba(var(--ion-color-contrast-rgb), 0.1)
// --------------------------------------------------------------------------------------------
@function current-color($variation, $alpha: null) {
  @if $alpha == null {
    @return var(--ion-color-#{$variation});
  } @else {
    @return rgba(var(--ion-color-#{$variation}-rgb), #{$alpha});
  }
}

// Mixes a color with black to create its shade.
// --------------------------------------------------------------------------------------------
@function get-color-shade($color) {
  @return mix(#000, $color, 12%);
}

// Mixes a color with white to create its tint.
// --------------------------------------------------------------------------------------------
@function get-color-tint($color) {
  @return mix(#fff, $color, 10%);
}

// Converts a color to a comma separated rgb.
// --------------------------------------------------------------------------------------------
@function color-to-rgb-list($color) {
  @return #{red($color)},#{green($color)},#{blue($color)};
}
