ion-icon {
  &.is-rotate {
    will-change: transform;
    transition: transform 0.5s ease;
  }

  &.rotate {
    &-180 {
      transform: rotate(-180deg);
    }
  }
}

.ion-transparent-header {
  --border-width: 0;

  &.header-md::after {
    display: none;
  }

  ion-toolbar {
    --background: transparent;
    --ion-color-base: transparent !important;
    --opacity: 1;
    --border-color: transparent;

    &:last-of-type {
      --border-width: 0;
    }
  }
}

.ion-vertical-align-center {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

ion-button[shape=circle] {
  height: 36px;
  width: 36px;

  --padding-start: 0;
  --padding-end: 0;

  &::part(native) {
    border-radius: 50%;
    padding: 6px;
    font-size: 1.6rem;
  }
}

.no-border-top {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

ion-modal.auto-height {

  --height: auto;

  .ion-page {
    position: relative;
    display: block;
    contain: content;

    .inner-content {
      max-height: 80vh;
      overflow: auto;
    }
  }
}

ion-modal.fullscreen {
  --width: 100%;
  --height: 100%;
}

ion-modal.align-top {
  align-items: flex-start;
}

ion-modal.auto-height.show-modal + ion-modal.auto-height.show-modal:not(la) {
  display: none;
}

ion-searchbar {
  &.is-readonly {
    .searchbar-input {
      pointer-events: none;
    }
  }

  &.is-success-color-icon {
    ion-icon {
      --icon-color: var(--ion-color-success) !important;
    }
  }

}

.ion-float-clear:after {
  content: "";
  display: block;
  clear: both;
}




