.thead {
  font-weight: 700;
}
body.scanner-active {
  --background: transparent;
  --ion-background-color: transparent;
  &>div {
    z-index: -1;
  }
}
